<div class="modal-bg">
    <div class="form modal-dialog-container">
        
        <div class="dialog-header">
            <h2>Members of project {{ this.project.name }}</h2>
            <div class="dialog-close-btn" (click)="closeDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </div>
        
        <app-manage-project-members-form [project]="project"></app-manage-project-members-form>
    </div>
</div>