<div class="modal-bg">
    <div class="dialog">
        <div class="dialog-header">
            <h2>Scripts</h2>
            <div class="dialog-close-btn" (click)="closeDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </div>
        This tool allows you to run pre-defined scripts on your dataset. Each script performs a specific task.

        <form>
            <fieldset>
                <select>
                    <option>Script 1</option>
                </select>
            </fieldset>
            <button>Run script</button>
        </form>
    </div>
</div>