<div class="modal-bg">
    <div class="form modal-dialog-container">

        <div class="dialog-header"><h2>Help</h2>
            <div class="dialog-close-btn" (click)="closeDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </div>

        <div class="dialog-content">
            <div class="help-content">
                
                <div *ngIf="getCurrentNavigation() == 'emu-webapp'">
                    <h2>What is this?</h2>
                    You are now running the EMU-webApp, which is a labeling and correction tool that offers a multitude of labeling and visualization features.

                    <h2>How to use this tool</h2>

                    <pre>
On the left side of the screen you will see your bundles. 
Only bundles that have been assigned to you in the dashboard interface by your project leader will be shown here.
Even if you are the project leader, you still need to assign the annotation of the bundles to yourself in order for them to show up here.

On your right you will be able to play back the bundle audio as well as analyze and annotate it in various ways. The EMU-webApp offers a multitude of tools for this purpose and is far too comprehensive to go through in detail here.

However, some commonly used functionalities are:

[INSERT EXAMPLES OF COMMON FUNCTIONALITIES HERE]
                    </pre>
                </div>

                <div *ngIf="getCurrentNavigation() == 'jupyter'">
                    <h2>What is this?</h2>
                    You are now running the Jupyter notebook application.
                    Using the emuR library, you can load your Emu-R database and perform various analyses on it.
                    The code you run here is executed on the VISP server and not on your local machine.

                    <h2>How to use this tool</h2>
<pre>
Write your code in the editor on the right side of the screen.
On your left you will see your project directory.

You need to save your work in order for it to be stored on the VISP server. 
You can go back and forth between the dashboard and the Jupyter session without losing your work.
However, the system will periodically shut down idle sessions, so save your work and don't count on your session being available
the next time you login.
</pre>
                </div>

                <div *ngIf="getCurrentNavigation() == 'rstudio'">

                    <h2>What is this?</h2>
                You are now running RStudio with access to your project.
                Using the emuR library, you can load your Emu-R database and perform various analyses on it.
                The code you run here is executed on the VISP server and not on your local machine.

                <h2>How to use this tool</h2>
<pre>
Write your code in the editor on the left side of the screen.
On your lower right you will see your project directory.

You need to save your work in order for it to be stored on the VISP server. 
You can go back and forth between the dashboard and the RStudio session without losing your work.
However, the system will periodically shut down idle sessions, so save your work and don't count on your session being available
the next time you login.

Please see <a href="https://ips-lmu.github.io/The-EMU-SDMS-Manual/" target="_blank">The EMU-SDMS Manual</a> for more information on how to use the emuR library.
</pre>
                
                </div>

                <div *ngIf="getCurrentNavigation() == 'jupyter' || getCurrentNavigation() == 'rstudio'">
                <h2>Code examples</h2>
                <ul>
                    <li>
                        <h3>Loading your Emu-R database</h3>
                        <div class="code-container">
                        <pre><code id="code1">
# Load the emuR library
library(emuR)

# Load your Emu-R database. Within Visible Speech, the database location is assumed to be the same for all projects,
# This command will therefore always provide you with a reference ’VISP’ to your speech database
db_path <- "~/project/Data/VISP_emuDB"
VISP <- load_emuDB(db_path)
                        </code></pre>
                        <button class="copy-btn" (click)="copyCode('code1')">Copy</button>
                        </div>
                    </li>

                    <li>
                        <h3>View the sessions in your database</h3>
                        <div class="code-container">
                        <pre><code id="code2">
# List the recording sessions in your database
sessions <- list_sessions(VISP)
print(sessions)
                        </code></pre>
                        <button class="copy-btn" (click)="copyCode('code2')">Copy</button>
                        </div>
                    </li>

                    <li>
                        <h3>List bundles</h3>
                        <div class="code-container">
                        <pre><code id="code3">
#”Bundles” is the name used for individual sound files and associated other signal files in a session.
# You can list all bundles in a database

list_bundles(VISP)

# You can also list all bundles or sessions matching a pattern
# In the example below you will get all the bundles of sessions with names (perhaps recording date) starting with 2024

list_bundles(VISP,sessionPattern="2024.*")

# You can always get help for a command using ¨?¨, e.g.

?list_bundles
                        </code></pre>
                        <button class="copy-btn" (click)="copyCode('code3')">Copy</button>
                        </div>
                    </li>

                    <li>
                        <h3>Other usage</h3>
                        <div class="code-container">
                        <pre><code id="code3">
# Anything that is a attribute definition can be queried for labels.
# We here assume that you have a Word level set up, similar to our default setup

query(VISP,"Word !=''") #Provides a list of all non-empty word labels

query(VISP,"Word =~ '^s.*'") # Uses a regular expression to find all words beginning with ‘s’

query(VISP,"Word !=''. -> Word =~ '^s.*'") # Finds all (not empty) word labels preceeding a word starting with ‘s’  

# We can make queries that relate to the link between transcription tiers

# (note the [] braces)

query(VISP,"[Phonetic != '' ^ Word =~ '^s.*']")
                        </code></pre>
                        <button class="copy-btn" (click)="copyCode('code3')">Copy</button>
                        </div>
                    </li>

                </ul>
            </div>


                <!--<span (click)="startTour()">Tutorial</span>-->
            </div>
        </div>

    </div>
</div>