<div id="project-manager-root">
    <div class="project-manager-header">
        <div class="project-manager-title-container">
            <h2>Your Projects</h2>
            <button *ngIf="showCreateProjectButton" id="create-project-btn" (click)="showProjectDialog()">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                Create new project
            </button>
        </div>
    </div>

    <ul>
        <app-project-item *ngFor="let project of projects" [project]="project" [projectManager]="this"></app-project-item>
    </ul>

    <div *ngIf="!projectsLoaded">
        <span class="projects-loading-indicator" *ngIf="!projectsLoaded">
            <img src="../../../assets/loading-indicator.svg">
        </span>
        Loading your projects...
    </div>
    <div *ngIf="projectsLoaded && projects?.length == 0">
        You have no projects
    </div>

    <app-project-dialog *ngIf="(dashboard.modalActive && dashboard.modalName=='project-dialog')" [projectManager]="this"></app-project-dialog>
    <app-script-app-dialog *ngIf="(dashboard.modalActive && dashboard.modalName=='script-dialog')"></app-script-app-dialog>
    <!--<app-import-audio-form *ngIf="(dashboard.modalActive && dashboard.modalName=='import-audio-dialog')" [projectManager]="this"></app-import-audio-form>-->
    <app-edit-emudb-dialog *ngIf="(dashboard.modalActive && dashboard.modalName=='emudb-dialog')" [projectManager]="this"></app-edit-emudb-dialog>
    <app-manage-project-members-dialog *ngIf="(dashboard.modalActive && dashboard.modalName=='manage-project-members-dialog')" [projectManager]="this"></app-manage-project-members-dialog>
    <app-manage-bundle-assignment-dialog *ngIf="(dashboard.modalActive && dashboard.modalName=='manage-bundle-assignment-dialog')" [projectManager]="this"></app-manage-bundle-assignment-dialog>
    <app-manage-sessions-dialog *ngIf="(dashboard.modalActive && dashboard.modalName=='manage-sessions-dialog')" [projectManager]="this"></app-manage-sessions-dialog>
    <app-manage-spr-scripts-dialog *ngIf="(dashboard.modalActive && dashboard.modalName=='spr-scripts-dialog')" [projectManager]="this"></app-manage-spr-scripts-dialog>
</div>