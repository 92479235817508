<div class="modal-bg">
    <div class="form modal-dialog-container">
        
        <div class="dialog-header">
            <h2>Assign annotation tasks of project {{ this.project.name }}</h2>
            <div class="dialog-close-btn" (click)="closeDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </div>
        
        <app-manage-bundle-assignment-form [project]="project"></app-manage-bundle-assignment-form>
    </div>
</div>