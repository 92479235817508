<div class="info-box" [ngSwitch]="infobox.name">
    <div class="info-box-main-container">
        <div class="info-box-header">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            <h2>{{ infobox.title }}</h2>
        </div>
        <div class="info-box-content" *ngSwitchCase="'tools-and-resources'">
            <ul>
                <li class="link-button" *ngIf="octraEnabled">
                    <a href="https://octra.{{ baseDomain }}" target="_blank">
                        <i class="fa fa-commenting" aria-hidden="true"></i><div>Octra</div>
                    </a>
                </li>
                <li class="link-button" *ngIf="labjsEnabled">
                    <a href="https://labjs.{{ baseDomain }}" target="_blank">
                        <i class="fa fa-flask" aria-hidden="true"></i><div>Lab.js</div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="info-box-content" *ngSwitchCase="'help-and-guides'">
            <ul>

                <li class="link-button">
                    <a (click)="tutorial()">
                        <i class="fa fa-info-circle" aria-hidden="true"></i><div>VISP Tutorial</div>
                    </a>
                </li>

                <li *ngIf="emuWebAppEnabled" class="link-button">
                    <a href="https://ips-lmu.github.io/The-EMU-SDMS-Manual" target="_blank">
                        <i class="fa fa-book" aria-hidden="true"></i><div>EMU Manual</div>
                    </a>
                </li>

                <li class="link-button">
                    <a href="mailto:support@humlab.umu.se" target="_blank">
                        <i class="fa fa-envelope" aria-hidden="true"></i><div>E-mail</div>
                    </a>
                </li>
                
            </ul>
        </div>
        <div class="info-box-content" *ngSwitchCase="'data-management'">
            We aim to provide an integrated workflow where your data is securely kept on our servers using Git for version control.
        </div>
    </div>
</div>