<div class="modal-bg">
    <div class="form modal-dialog-container">
        <div class="dialog-header"><h2>{{ dialogTitle }}</h2>
            <div class="dialog-close-btn" (click)="closeCreateProjectDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </div>

        <form [formGroup]="form" #f="ngForm">

            <fieldset class="fieldset">
                <label>Project name</label>
                <input id="projectName" placeholder="Project name" formControlName="projectName">

                <div *ngIf="projectName.pristine == false && projectName.status == 'INVALID'" >
                    <div class="form-error-msg" *ngIf="projectName.errors?.isFormNameTaken">
                        That name is already taken
                    </div>
                    <div class="form-error-msg" *ngIf="projectName.errors?.required">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        Project name is required
                    </div>
                    <div class="form-error-msg" *ngIf="projectName.errors?.minlength">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        Project name should be at least {{ projectName.errors.minlength.requiredLength }} characters long
                    </div>
                    <div class="form-error-msg" *ngIf="projectName.errors?.maxlength">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        Project name should be at most {{ projectName.errors.maxlength.requiredLength }} characters long
                    </div>
                    <div class="form-error-msg" *ngIf="projectName.errors?.pattern">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        Please avoid special characters in the name
                    </div>
                </div>
                
            </fieldset>

            <!--
            <app-documentation-form *ngIf="standardDirectoryStructure.value" [context]="this"></app-documentation-form>
            <app-sessions-form [project]="this.project" [parentForm]="this" [formContextId]="this.formContextId" [projectManager]="this.projectManager"></app-sessions-form>
            -->

            <app-documentation-form #documentationForm *ngIf="standardDirectoryStructure.value" [context]="this"></app-documentation-form>
            <app-sessions-form #sessionsForm [project]="this.project" [parentForm]="this" [formContextId]="this.formContextId" [projectManager]="this.projectManager"></app-sessions-form>



            <br />
            <button id="submitBtn" type="button" [ngClass]="{'disabled': !submitBtnEnabled}" [disabled]="!submitBtnEnabled" (click)="saveProject(f)">
                <img *ngIf="showLoadingIndicator" src="../../../../assets/loading-indicator-4-light.svg">
                <i *ngIf="!showLoadingIndicator" class="fa fa-save" aria-hidden="true"></i>
                &nbsp;
                {{ submitBtnLabel }}
            </button>

        </form>
    </div>
</div>
